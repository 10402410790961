@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}
.map-container {
  height: calc(100vh - 65px);
  width: 100vw;
}

.hidden {
  display: none;
}

.leaflet-control-basemapsSwitcher {
  height: auto;
  width: auto;
  display: flex;
}

.leaflet-control-basemapsSwitcher:hover {
  cursor: pointer;
}

.leaflet-touch .leaflet-bar a {
  width: 35px;
  height: 35px;
}

.basemapImg {
  box-shadow: 2px 2px 5px rgb(0 0 0 / 65%);
  background-color: #fff;
  /* margin: 5px; */
  padding: 5px;
  border-radius: 3px 3px;
  display: none;
}

.basemapImg div {
  background-size: 70px 70px;
  width: 55px;
  height: 55px;
  border-radius: 3px 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.basemapImg div .name {
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 0;
  color: black;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 1px;
}

.basemapImg div .check {
  width: auto;
  height: auto;
  border-radius: 0;
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  padding: 1px;
  content: "";
}

.activeMap::before {
  color: white;
  content: "✔";
  width: 18px;
  height: 18px;
  background-color: #387b23;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tb-title {
  font-size: 1.1rem;
  font-weight: 600;
}
.table tr th,
.table tr td {
  border: 1px solid #000;
  padding: 5px;
}

.left-side-div {
  position: absolute;
  top: 5em;
  left: 0.5em;
  width: 80% !important;
  z-index: 500;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.pac-container {
  z-index: 100000;
}

.search-input-box #searchTxt {
  width: 100% !important;
}
.color-palette > label {
  position: relative;
}
.color-palette .check-icon {
  position: absolute;
  top: -6px;
}
.icons {
  cursor: pointer !important;
}
.checkedbox {
  background-color: #adf802 !important;
  color: #fff;
}

#add-location-form .label {
  display: flex;
  align-items: center;
  gap: 10px;
}
#add-location-form .form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #ced4da !important;
}
#add-location-form #searchTxt {
  height: 42px !important;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.user-img-cover {
  margin-left: 6px;
  width: 45px;
  height: 45px;
}

.user-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #adf802;
}

.profileItem {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.profileList {
  position: absolute;
  display: none;
  width: 120px;
  background-color: white;

  left: -30%;
  z-index: 10000;

  transform: translateX(-50%);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.33);
  border-radius: 5px;
}
.profileItem:hover .profileList {
  display: block;
}

.profileList ul {
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 100;
}

.profileList ul > li {
  padding: 0px 10px;
  text-align: start;
  cursor: pointer;
  z-index: 10;
  height: 50px;
  display: flex;
  align-items: center;
}
.profileList li:hover {
  background-color: #adf802;
  color: black;
}

.add-data-map {
  width: 20em !important;
  position: absolute;
  z-index: 500;
  top: 9em;
  left: 1.5em;
  border-radius: 20px;
  background-color: white;
  padding: 20px 15px !important;
  /* padding-left: 10px;
  padding-right: 10px; */
}

.main-heading {
  font-size: 10px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}

.tab-left {
  padding: 10px 0px;
  width: 150px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  color: black;
  background-color: #d9d9d9;
  /* font-size: 10px; */
  text-align: center;
}

.tab-right {
  padding: 10px 0px;
  width: 150px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  color: black;
  background-color: #d9d9d9;
  /* font-size: 10px; */
  text-align: center;
}

.select-default .ant-select-selector {
  border-radius: 20px !important;
  background-color: #f6f6f6 !important;
  height: 45px !important;
  align-items: center !important;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  display: flex;
  gap: 10px;
  border: none !important;
  bottom: 1em;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-radius: 50% !important;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-radius: 50% !important;
}

.chevron-down {
  position: absolute;
  left: 120px;
  top: 18px;
  color: white;
}

.chevron-left {
  position: absolute;
  right: 70px;
  top: 18px;
}

.project-detail-div {
  width: 75vw !important;
  height: 75vh !important;
  position: absolute;
  z-index: 500;
  top: 9em;
  /* right: 1.5em; */
  left: 22em;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
}

.project-list-div {
  width: 200px !important;
  height: 360px !important;
  /* height: 75% !important; */
  border-radius: 10px;
  background-color: #d9d9d9;
  overflow: hidden;
}

.primary-icon {
  color: gray;
}

.secondary-icon {
  color: #fefefe;
}

.active-proj {
  /* background-color: #71d622; */
  /* opacity: 10%; */
  background-color: rgba(113, 214, 34, 0.1);
}

.modal-custom-input {
  border-radius: 20px !important;
  background-color: #f6f6f6 !important;
  height: 45px;
}

.poi-location-text {
  font-size: 10px !important;
}

.css-1e9khus {
  height: 63vh;
}

.css-15u88h5 {
  min-height: 60vh !important;
}

.ant-radio-wrapper .ant-radio-inner {
  border: 1px solid lightgray;
  padding: 10px;
  background-color: none;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border: 1px solid lightgray;
  padding: 10px;
  background-color: #71d622;
}

.MuiSlider-markLabel {
  font-size: 18px !important;
  /* left: 95% !important; */
}

.modal-footer.modal-custom-footer {
  border: none !important;
}

.modal-header.modal-custom-header {
  border: none !important;
}

.modal-dialog .modal-content {
  padding: 20px 10px !important;
  border: none !important;
}

input[type="text"]::placeholder,
.italic-input::placeholder {
  font-style: italic;
}

input[type="radio"].modal-custom-radio {
  appearance: none;
  /* padding: 10px; */
  width: 20px;
  height: 20px;
  border: 2px solid lightgray;
  background-color: #f6f6f6;
  border-radius: 50%;
}

input[type="radio"]:checked.modal-custom-radio {
  background-color: #71d622;
  border: 2px solid lightgray;
}

.react-confirm-alert-overlay {
  z-index: 9999999 !important;
}

input[type="search"]:focus.header-custom-search {
  box-shadow: none;
  border-color: transparent;
}

.react-confirm-alert-overlay {
  /* background-color: lightgray !important; */
  background-color: rgba(108, 108, 108, 0.7) !important;
}

.custom-confirm-alert {
  padding: 40px 20px !important;
  /* border: 1px solid lightgray !important; */
  background-color: #f6f6f6 !important;
  border-radius: 20px !important;
  /* width: 70% !important;
  margin: auto !important; */
}

/* .header-custom-search:active {
  border-bottom: "2px solid #e7e7e7";
  outline: none;
} */

/* .modal-custom-footer.modal-footer {
  border: none !important;
} */

/*  */
/* input[type='range']::-webkit-slider-thumb {
  width: 6px;
  -webkit-appearance: none;
  height: 12px;
  background: black;
  border-radius: 2px;
}
input[type=range]::-webkit-slider-runnable-track {
background-color: #387b23 !important;
}

input[type=range]::-moz-range-track {
background-color: #adf802 !important;
} */

/* section.pricing {
  background: #007bff;
  background: linear-gradient(to right, #0062e6, #33aeff);
} */

.subscription-modal .modal-content {
  padding: 0px !important;
  width: 50% !important;
  margin: auto;
}

.subscription-card-container {
  padding: 10px;
}

.selected-card {
  border: 3px solid #71d622 !important;
  border-radius: 25px !important;
}

/* .pricing {
  background: #007bff;
  background: linear-gradient(to right, #0062e6, #33aeff);
} */

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}

.gray-text {
  /* color: gray; */
  color: #6c757d;
  font-size: 14px;
}

.login-btn {
  width: 100%;
  background-color: #71d622;
  color: white;
  border: none;
  border-radius: 7px;
  padding: 5px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel-btn {
  width: 100%;
  background-color: transparent;
  color: #ff5d39;
  border: 1px solid #ff5d39;
  border-radius: 7px;
  padding: 5px 0px;
}

.learn-more:hover {
  color: #6c757d;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .card:hover .btn {
    opacity: 1;
  }
}

@media screen and (max-width: 990px) {
  .subscription-modal .modal-content {
    padding: 0px !important;
    width: 75% !important;
    margin: auto;
  }

  /* .subscription-modal .modal {
    --bs-modal-width: 1000px !important;
    padding: 0px !important;
    width: 50% !important;
    margin: auto;
  } */
}
