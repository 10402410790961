/* body {
  font-family: "Plus Jakarta Sans" !important;
} */

:root {
  --toastify-color-info: #adea60;
}

.ant-select-selection-placeholder {
  /* color: black !important; */
}

.Toastify__toast-container--top-center {
  top: 4em;
}

.ant-switch.ant-switch-checked {
  background: #4dc772;
}

.ant-switch .ant-switch-inner .ant-switch-inner-checked {
  color: black;
}

/* .hoverInfo {
  position: absolute;
  top: 5em;
  right: 0.75em;
  z-index: 5000;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  text-align: center;
  /* border: 2px solid red; 
  min-width: 9rem;
} */

/* .hoverInfo {
  position: absolute;
  bottom: 1em;
  /* right: 0.75em; */
/* right: 45%;
  z-index: 5000;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  text-align: center;
  /* border: 2px solid red; */
/*min-width: 9rem;
} */

.hoverInfo {
  position: absolute;
  bottom: 2em;
  right: 37%;
  z-index: 5000;
  background: #fff;
  padding: 10px;
  border-radius: 20px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  min-width: 9rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

.hoverHeading {
  color: #000;
  font-weight: bold;
  font-size: 10px;
  word-break: break-word;
}

.hover-heading-green {
  color: #71d622;
  font-weight: bold;
  font-size: 12px;
  word-break: break-word;
}

.adminDiv {
  /* position: fixed;
  top: 5em;
  left: 1em; */
  z-index: 500;
  background: #fff;
  padding: 15px;
  border-radius: 0px;
  width: 200px;
  text-align: left;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.categoriesDiv {
  position: fixed;
  top: 11.5em;
  left: 3em;
  z-index: 500;
  background: #d4d4d4;
  padding: 5px;
  border-radius: 15px;
  width: 275px;
  text-align: center;
}

.heatMapSwitcher {
  position: absolute;
  left: 8em;
  border-radius: 20px;
  background-color: white;
  bottom: 2em;
  z-index: 5000;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* .heatMapSwitcher > div {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
} */

/* .selectDefault {
  background: White;
  border-radius: 5px;
  min-width: 125px;
  max-width: fit-content;
  text-align: center;
} */

.ant-spin {
  color: #adea60;
}

.ant-spin .ant-spin-dot-item {
  background-color: #adea60;
}

.ant-spin-nested-loading > div > .ant-spin {
  /* position: unset; */
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #d3d3d3;
}

button[type="submit"] {
  text-indent: -999px;
  overflow: hidden;
  width: 40px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center;
  cursor: pointer;
  opacity: 0.7;
}

button[type="submit"]:hover {
  opacity: 1;
}

button[type="submit"]:focus,
input[type="search"]:focus {
  box-shadow: 0 0 3px 0 #1183d6;
  border-color: #1183d6;
  outline: none;
}

form.nosubmit {
  border: none;
  padding: 0;
}

input.nosubmit {
  border: 1px solid #555;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url("https://api.mapozone.com/v2/times-circle.svg") no-repeat 50%
    50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  display: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.1;
  pointer-events: all;
  cursor: pointer;
}

input[type="search"].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

.leaflet-bottom.leaflet-left .leaflet-control:nth-child(2) {
  clear: none;
}

/* .leaflet-bottom.leaflet-right .leaflet-control:nth-child(1):not(:only-child) {
  bottom: 2em;
} */

/* .leaflet-control-zoom {
  margin-top: 2em !important;
} */

.ant-select {
  text-align: left !important;
}

.my-background {
  background-color: white;
  border-radius: 20px;
  min-height: 45px;
  /* width: 600px; */
  font-size: 10px;
  font-weight: bold;
  color: #000;
  opacity: 70%;
  margin-top: 60px;
}

/* .Toastify__toast-container--top-center:has(> .my-background) {
  left: 43%;
} */

/* .Toastify__toast-container--top-center */

.flex-box {
  display: flex;
  justify-content: right;
}

.location-btn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  /* padding-left: 35px;
  padding-right: 35px; */
  background-color: white;
  font-weight: 700;
  border: none;
  font-size: 10px;
  gap: 5px;
  border-radius: 35px;
  opacity: 70%;
}

.location-btn1-active {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 15px;
  padding-left: 35px;
  padding-right: 35px; */
  background-color: #71d622;
  font-weight: 700;
  border: none;
  /* font-size: 14px; */
  gap: 5px;
  border-radius: 35px;
  color: white;
  padding: 10px 15px;
  font-size: 10px;
}

.location-btn2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.6rem;
  width: 20rem;
  height: 3rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem !important;
  /* border--radius:0rem ; */
  background-color: white !important;
  /* margin-right: 1rem; */
  /* text-align: left; */
  font-weight: 700;
  border: none;
  /* padding: 1rem 0.4rem 1rem 0.8rem; */
  font-size: 10px;
}
.AddDataToMapText {
  width: fit-content;
  font-size: 14px;
}
.AddDataToMapSearch {
  margin-left: 3px;
  margin-right: 5px;
}
.MyLocationLocationIcon {
  margin-left: 3px;
  margin-right: 13px;
  font-size: 18px;
}
.AddDataTOMapDropDown {
  width: 20px;
  height: 20px;
}

.location-btn2 {
  /* display: flex; */
  flex-direction: row;
  width: 200px;
  height: 3rem;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background-color: #adf802;
  font-weight: 700;
  border: none;
  font-size: 16px;
}

.location-btn3 {
  display: flex;
  flex-direction: row;
  width: 12rem;
  height: 3rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: #adf802;
  font-weight: 700;
  border: none;
  font-size: 16px;
}

.modal-footer {
  justify-content: center;
}

.custom-input {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  width: 20rem;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

/* .modal-content {
  position: relative;
  display: none;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  margin-top: 1rem;
} */
.color-palette {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.color {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}
.volume-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: black;
  margin-top: 0.5rem;
  align-items: center;
}

.checkbox1 {
  margin-left: 1rem;
  margin-top: 1rem;
}
.volume-slider {
  background-color: limegreen;
  width: 22rem;
  cursor: pointer;
  --slider-thumb-color: limegreen;
  --slider-track-color: limegreen;
}

.icon-flex {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.icons {
  border: solid 1px black;
  border-radius: 0.4rem;
  padding: 0.1rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  background-color: white;
}

.input-range {
  background-color: green;
  height: 5px;
}

.input-range-track {
  background-color: transparent;
}

.input-range-active-track {
  background-color: green;
}

.input-range-slider {
  background-color: green;
  border: none;
}
input[type="range"] {
  accent-color: limegreen;
}

.icon > span {
  vertical-align: 0.1em !important;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .modal-backdrop {
    opacity: 1 !important;
    width: 100% !important;
    height: 100% !important;
    /* z-index: 1055 !important; */
  }

  /* .loginModo {
    display: none;
  } */
}

.custom-tooltip .leaflet-tooltip {
  z-index: 9999999 !important;
}

.custom-popup {
  z-index: 99999999 !important;
}

.search-btn {
  width: 100%;
  border-radius: 20px;
  background-color: #71d622;
  font-weight: 700;
  border: none;
  font-size: 14px;
  padding: 5px;
  color: white;
  /* height: 45px; */
}

.hide-btn {
  width: 100%;
  border-radius: 20px;
  background-color: #d9d9d9;
  font-weight: 700;
  border: none;
  font-size: 14px;
  padding: 5px;
  color: black;
}

/* .add-project-btn:hover {
  color: #fff;
  background-color: #a0bd5c;
} */

/* .leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important} */

.green-bg {
  background-color: #71d622;
  color: white;
}

.custom-tabs {
  text-transform: uppercase;
  font-weight: 900;
  cursor: pointer;
}

.MuiSlider-root {
  color: lightslategray !important;
}

.MuiSlider-thumb {
  color: #71d622 !important;
}

/* span .MuiSlider-mark {
  background-color: "#000000";
} */
