.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
height: 90vh;
}
  
  .login-container {
    text-align: center;    
    padding: 20px;    
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 400px;
    height: 350px;
    
  }

  .login{
    margin-bottom: 5rem;
  }
  
  .login-container h2 {
    margin-bottom: 20px;

}
.font-l{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 24px;
font-weight: 700; 
}


:where(.css-dev-only-do-not-override-byeoj0).ant-input-affix-wrapper >input.ant-input {
    padding: 0;
    font-size: inherit;
    border: none;
    border-radius: 0;
    outline: none;
    background-color: #e7dfdf;
}

  
  .social-buttons {
    margin-top: 20px;
  }
  
  .btn1{
    background-color: #45bd69;
    color:"white";
    text-align:justify;
    align-items:baseline;
    font-weight: 500;
    margin-top: 1rem;
    height: 3rem;  
    
  }


  /* .forgot-password{
 display: flex;
text-decoration: none;
color: blue;
margin-left: 150px;
width: 100%;
} */
    

  :where(.css-dev-only-do-not-override-byeoj0).ant-btn.ant-btn-block {
    width: 100%;
    text-align: inherit;
}



  .forgot-password{
display: flex;
flex-direction: row;
text-decoration: none;
color: blue;
width: 50%;
justify-content: end;
margin-bottom: 1rem;
}


  :where(.css-dev-only-do-not-override-byeoj0).ant-btn.ant-btn-block {
    width: 100%;
    text-align: center;
    color: white;
}


  /* .facebook-button {
    background-color: #fff;
    color: #3b5998;
    border: 1px solid black;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
  } */
  
  .google-button {
    background-color: #fff;
    font-weight: 600;
    color: #db4a39;
    border: 1px solid #db4a39;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;  
    height: 3rem;
}
  
  :where(.css-dev-only-do-not-override-byeoj0).ant-input-affix-wrapper {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    padding: 15px;
    margin: 5px;
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}

:where(.css-dev-only-do-not-override-byeoj0).ant-input-affix-wrapper >input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: #e7dfdf;
  height: 2rem;
}
:where(.css-dev-only-do-not-override-byeoj0).ant-btn:not(:disabled):focus-visible {
    outline: none !important; 
    /* outline-offset: 1px;
     transition: outline-offset 0s,outline 0s; */
}
.signup-link{
    color: gray;
    margin-top: 10px;

  }

  .signup-link2{
margin-top: 0.8rem;

font-size: 12px;
  }
  .input{
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    background-color: #e7dfdf; 
  }
  
button[type="submit"].register-submit{
  width: 100%;
  background: green;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  text-indent: 0px;
  text-align: center;
  justify-content: center;
}