/* Profile.css */
#profile-page{
background: #EAEAEA;
.cursor-pointer{
    cursor: pointer !important;
    
}
.container {
    min-width: 100%;
    max-width: 100%;
    // width: 100%;
    // min-width: 100%;
    // height: 100%;
    // padding: 0px;
    // margin: 0px;
    // height: 603px;
    // top: 91px;
    // left: 8px;
    // background: #F4F4F4;
    // display: flex;
    // overflow-x: auto;
    // margin-top: 120px;
    // margin-bottom: 12px;
}

.header {
    display: flex;
    align-items: center;
}

.LeftSideContainer {
    display:flex;
    flex-direction: column;
    margin-top: 9px;
    height: 90vh;
    top: 142px;
    left: 26px;
    border-radius: 7px;
}

.ReturnButton{
        display: flex;
        align-items: center; 
        width: 100%;
        height: 45px;
        border-radius: 7px;
        background: #000000;
        box-shadow: 0px 4px 4px 0px #00000040;
        margin: 0px auto;
        margin-bottom: 5px;
}

.ArrowIcon{
    width: 15px;
    height: 65px;
    top: 122px;
    left: 57px;
    border: 2px;
    color: #FFFFFF;
    margin-left: 6px;
}
.ReturnToMapText{
    width: 211px;
    height: 30px;
    font-family:sans-serif;
font-size: 16px;
font-weight: 700;
line-height: 39px;
letter-spacing: 0em;
color: white;
   
}
.MyProjectContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    border-radius: 7px;
    padding: 9px;
    margin-top: 5px;
    background: #FFFFFF;

}
.MyProject {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 22px;
    font-family: Plus Jakarta Sans;
background: #fffefe; 
margin-top: 8px;
}
.MyProjectText{
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 750;
    letter-spacing: 0em;
    margin-top: 6px;
}
.add-icon {
    width: 50px;
    height: 18px;
    margin-right: -12px;
}
/* CSS for the Popup */
/* Define the keyframes animation */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* CSS for the Popup */
.AddProjectNamePopup {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2px;
  display: none; /* Initially hide the popup */
  animation: slideInFromLeft 0.3s ease-in-out; /* Apply the animation */
}

.AddProjectNamePopup h6 {
  font-family: sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin: 0;
  color: #333; /* Text color */
}

/* Customize the background color for your AddProjectNamePopupText class */
.AddProjectNamePopupText {
  font-family: sans-serif;
  width: max-content;
  font-size: 8px;
  font-weight: 500;
  letter-spacing: 0em;
  margin-top: 2px;
  background-color: #ee6d09;
  padding: 4px 6px; /* Adjust padding for better appearance */
  border-radius: 8px; /* Add rounded corners */
  color: #fff; /* Text color */
}

  
.MyProjectBottomLine{
    width: 100%;
    background: #BABABA;
    border: 2px solid #BABABA;
    margin-top: 5px;
}
.PersonalBoard1Rectangle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 2px;
    // border: 1px solid transparent;
    background: #EAEAEA;
    
    
// border: 1px solid #000000;
box-shadow: 0px 1px 2px 0px #0000001C;
}
.PersonalBoard1Text{
    width: 100%;
    height: 28px;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
   margin-left: 13px; 
}
.DeleteIcon{
    width: 18px;
    height: 18px;
       margin-right: 4px;
}

.RightSideContainer {
    width: 1790px;
    height: 587px;
    border-radius: 7px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 0px #00000040;
    margin: 9px;
    padding: 9px; 
}
.PersonalBoard1Text1{
    width: 466px;
    font-family:sans-serif;
font-size: 18px;
font-weight: 555;
line-height: 34px;
letter-spacing: 0em;
text-align: left;
color: #000000; 
margin-left: 4px;  
}
.PersonalBoard1TextBottomLine{
    width: 1012.0000000000039px;
    border: 1px;
    background: #BABABA;
    border: 1px solid #BABABA; 
    margin-top: 0px;
}
.MainContainer{
    display: flex;
    flex-direction: row;
}
.TargetLocationContainer{
    display: flex;
    flex-direction: column;
    // width: 198px;
    // height: 369px;
    border-radius: 7px;
    background: #EAEAEA;
    box-shadow: 0px 4px 4px 0px #00000040;
margin: 3px;
margin-left: 5px;
margin-right: 5px;
}
.TargetLocation{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    width: 100%;
height: 30px;
border-radius: 7px 7px 0px 0px;
background: #FFD748;
}
.TargetLocationText{
    // width: 132px;
font-family: sans-serif;
font-size: 10px;
font-weight: 700;
line-height: 34px;
letter-spacing: 0em;
color: #000000;
margin-left: 7px;
margin-top: 8px;
}
.TargetlocationCheckBox{
    width: 19px;
height: 17px;
border: 1px;
background: linear-gradient(0deg, #000000, #000000),
linear-gradient(0deg, #FFB547, #FFB547);
border: 1px solid #000000;
margin-left: 15px;
}
.TargetLocationDropdownIcon{
    width: 8px;
height: 9px;
color: #252525;
margin:3px;

}
.TargetLocationDeleteIcon{
    width: 15px;
height: 18px;
margin: 3px;
margin-right: 8px;
}
.SelectAllBox{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-top: 9px;
}
.CheckBoxSelectAll{
    width: 15.35706901550293px;
height: 11.35706901550293px;
border: 1px solid #000000;
margin-left: 12px;
}
.SelectAllText{
width: 152px;
font-family: sans-serif;
font-size: 10px;
font-weight: 400;
line-height:12px;
letter-spacing: 0em;
color: #000000;
margin-left: 9px;
}
.SelectAllTextBottomLine{
    width: 179px;
border: 1px;
background: #B8B8B8;
border: 1px solid #B8B8B8;
margin-top: 0px;
margin-left: 9px;
}
.LocationNameField{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
width: 179px;
height: 28px;;
border-radius: 7px;
background: #FFFFFF;
box-shadow: 0px 4px 4px 0px #00000040;
margin-left: 8px;
}
.LocationNameCheckBox{
    width: 12px;
height: 12px;
border: 1px;
border: 1px solid #000000;
margin-left: 5px;
}
.LocationNameText{
    width: 152px;
    font-family: sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    margin-top: 8px;
 margin-left: 9px;
}
.LocationNameEditIcon{
    width: 9px;
height: 9px;
margin-left: 7px;
}
.LocationNameDeleteIcon{
    width: 15px;
    height: 12px;
    margin: 4px;
    margin-right: 7px;
}
.AddLocationBox{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 60%;
    padding: 10px  20px;
    height: auto;
    cursor: pointer;
    border-radius: 7px;
    margin: 0px auto;
    margin-top: 19px;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #DFDFDF;
    text-align: center;

}
.AddLocationaddIcon{
    // width: 29px;
// height: 15px;
// margin-left: 7px;
}
.AddLocationNameText{
    // width: 153px;
    // height: 28px;
    font-family: sans-serif;
font-size: 13px;
font-weight: 600;
margin-left: 8px;
// line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: #000000;
margin:  0px;


}
.PotentialLocationContainer{
    display: flex;
    flex-direction: column;
    width: 198px;
height: 280px;
border-radius: 7px;
background: #EAEAEA;
box-shadow: 0px 4px 4px 0px #00000040;
margin: 3px;
margin-left: 5px;
margin-right: 5px;
}
.PotentialLocationaHeader{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    width: 198px;
height: 30px;
border-radius: 7px 7px 0px 0px;
background: #FF70F9;
}





.LiveLocationContainer{
    display: flex;
    flex-direction: column;
    width: 198px;
    height: 187px;
    border-radius: 7px;
    background: #EAEAEA;
    background: #EAEAEA;
    margin: 3px;
margin-left: 5px;
margin-right: 5px;
}
.LiveLocationaHeader{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    width: 198px;
height: 30px;
border-radius: 7px 7px 0px 0px;
    background: #A8E25E;

}

.AddAnotherList{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 197px;
    cursor: pointer;
    height: 32px;
    border-radius: 7px;
    background: #DFDFDF;
    // margin: 3px;
    // margin-left: 5px;
    // margin-right: 5px;

}
.AddAnotherListaddIcon{
    width: 15px;
    height: 15px;
    margin-left: 40px;
    
}
.AddAnotherListText{
    width: 152px;
height: 28px;
font-family: sans-serif;
font-size: 13px;
font-weight: 550;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: #000000;
margin-left: 5px;
}













.FirstBox {
    height: 369px;
    width:198px;
    top: 182px;
    left: 208px;
    border-radius: 7px;
    background: #EAEAEA;
    ;
    box-shadow:#000000 25% ;
}

.ArrowIcon{
    cursor: pointer;
}


button[type="submit"]{
    width: fit-content !important;
    height: auto;
    color:  #fff;
    background-color: #0d6efd !important;
    &:hover{
        color:#CCC;
    }   
}


}


// #profile-page .modal-footer > .add-project-btn{
//     width: fit-content !important;
//     height: auto;
//     color:  #fff;
//     background-color: #0d6efd !important;
//     &:hover{
//         color:#CCC;
//     }



#add-project-btn {
    // background-color: #A8E25E !important;
    color: #000;
    // &:hover{
    //     color: #fff;
    //     background-color: #a0bd5c;
    // }

    border-radius: 0.5rem;
    background-color: #adf802;
    font-weight: 700;
    border: none;
    font-size: 16px;
    padding: 10px 20px;
}



.active-project{
    border: 2px solid #000;
}



.loading-section{
    display: flex;
        width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
.loader-11202 {
    border: 4px solid #3498db; /* Loader border color */
    border-top: 4px solid #f0f0f0; /* Background color, should match your background */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

.list-section{
    background: #fff;
    margin-top: 10px;
    border-radius: 7px;
    height: 88vh;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.checkbox-110{
    position: relative;
}
.overcheck{
    height: 16px;
    width: 14px;
    background: red;
    position: absolute;
    opacity: 0;
}