.Forgotpassword-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #fff;

}
  
  .Forgotpassword-container {
    text-align: center;    
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    width: 400px;
    height: 267px;
  }


  .Register{
    margin-bottom: 5rem;
  }

  .font-r{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 24px;
font-weight: 700; 
}

  .Register-container h2 {
    margin-bottom: 20px;

}


:where(.css-dev-only-do-not-override-byeoj0).ant-input-affix-wrapper >input.ant-input {
    padding: 0;
    font-size: inherit;
    border: none;
    border-radius: 0;
    outline: none;
    background-color: #e7dfdf;
}
  
  .social-buttons {
    margin-top: 20px;
  }

  /* svg[Attributes Style] {
    width: 1em;
    height: 1em;
    fill: gray;
} */
  
  .btn1{
    background-color: #45bd69;
    color:"white";
    text-align:center;
    align-items:baseline;
    font-weight: 500;
    margin-top: 1rem;
    height: 3rem;  
    
  }

  
  .forgot-link{
    text-decoration: none;
  }
  
  .forgot-m{
    margin-top: 1rem;
  }


  .btn1:hover{
    background-color: #339550;
 
    
  }

  :where(.css-dev-only-do-not-override-byeoj0).ant-btn.ant-btn-block {
    width: 100%;
    text-align: inherit;
}




  :where(.css-dev-only-do-not-override-byeoj0).ant-btn.ant-btn-block {
    width: 100%;
    text-align: center;
    color: white;
}


  .facebook-button {
    background-color: #fff;
    color: #3b5998;
    border: 1px solid black;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  /* .google-button {
    background-color: #fff;
    font-weight: 600;
    color: #db4a39;
    border: 1px solid #db4a39;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;  
    height: 3rem;
} */
  
  :where(.css-dev-only-do-not-override-byeoj0).ant-input-affix-wrapper {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    padding: 15px;
    margin: 5px;
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}

:where(.css-dev-only-do-not-override-byeoj0).ant-input-affix-wrapper >input.ant-input {
  padding: 0;
  font-size: inherit;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: #fff;
  height: 2rem;
}

:where(.css-dev-only-do-not-override-byeoj0).ant-btn:not(:disabled):focus-visible {
  outline: none !important; 
  /* outline-offset: 1px;
   transition: outline-offset 0s,outline 0s; */
}
.signup-link{
    color: gray;
    margin-top: 10px;

  }

  .signup-link2{
margin-top: 0.8rem;

font-size: 12px;
color: gray;
  }

  .dropdown-w{
    width: 100%;
}

  .dropdown {
    padding: 8px;
    font-size: 16px;
    /* margin-left: 5px; */
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 0.6rem;
    background-color:#f4f4f4;
}

  .input{
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f4f4f4; 
  }
.ant-input-prefix{
  margin-right: 10px;
}
  :where(.css-dev-only-do-not-override-15rg2km).ant-input-affix-wrapper >input.ant-input {
    padding: 0;
    font-size: inherit;
    border: none;
    border-radius: 0;
    outline: none;
    background-color: #e7dfdf;
}


select:focus-visible {
  outline: none;
}

.ant-input-prefix{
  margin-right: 10px !important;
}
.ant-input{
  padding: 10px !important;
}
.input{
  padding:2px;
  padding-left: 10px;
  margin: 10px 0px;
}


button[type="submit"].register-submit{
  width: 100%;
  background: green;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  text-indent: 0px;
  text-align: center;
  justify-content: center;

}

