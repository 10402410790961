.info {
  background: white;
  border-radius: 5px;
}

.legend {
  width: fit-content;
  padding: 10px;
  min-width: 11em;
}

.box {
  height: 15px;
  width: 15px;
  border: 1px solid black;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 3px;
  border-radius: 50%;
}

.legendheading {
  font-size: 10px;
}
